.AdultModal_container__HUE5_ {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 56px 16px;

  background-color: var(--modal-overlay-color);
}

.AdultModal_hidden__Xsi9L {
  display: none;
}

.AdultModal_modal__Jy668 {
  width: 100%;
  max-width: 500px;
  background-color: var(--modal-background-color);
  padding: 36px 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: var(--modal-border-radius);
}

.AdultModal_title__z5IpN {
  margin-bottom: 36px;
}

.AdultModal_button___cyFj {
  margin-bottom: 4px;
  justify-content: center;
}

.AdultModalRW_rejectButton__HcnMI {
  color: var(--text-accent-color);
}

.YoutubeSection_section__Y9SpF {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.YoutubeSection_title__RMxZQ {
  text-align: center;
  margin-bottom: 2rem;
}
.YoutubeSection_embedContainer__aH2r_ {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 80vh;
  max-width: 1440px;
}

.YoutubeSection_embedContainer__aH2r_ iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

@media (max-width: 990px) {
  .YoutubeSection_embedContainer__aH2r_ {
    height: 50vh;
  }

  .YoutubeSection_embedContainer__aH2r_ iframe {
    height: 50vh;
  }
}

@media (max-width: 600px) {
  .YoutubeSection_embedContainer__aH2r_ {
    display: flex;
    justify-content: center;
    height: 30vh;
  }

  .YoutubeSection_embedContainer__aH2r_ iframe {
    width: 100vw;
    height: 30vh;
  }
}

.YoutubeSectionRW_section__v5VOa {
  background-color: var(--background-color);
}

.YoutubeSectionRW_title__KdSr_ {
  color: #fff;
  font-size: 40px;
}

/* latin-ext */
@font-face {
  font-family: '__Poppins_91160d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/lp/_next/static/media/fe0777f1195381cb-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_91160d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/lp/_next/static/media/eafabf029ad39a43-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_91160d';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/lp/_next/static/media/a1386beebedccca4-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_91160d';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/lp/_next/static/media/b957ea75a84b6ea7-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_91160d';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_91160d {font-family: '__Poppins_91160d', '__Poppins_Fallback_91160d';font-style: normal
}.__variable_91160d {--font-poppins: '__Poppins_91160d', '__Poppins_Fallback_91160d'
}

