.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 56px 16px;

  background-color: var(--modal-overlay-color);
}

.hidden {
  display: none;
}

.modal {
  width: 100%;
  max-width: 500px;
  background-color: var(--modal-background-color);
  padding: 36px 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: var(--modal-border-radius);
}

.title {
  margin-bottom: 36px;
}

.button {
  margin-bottom: 4px;
  justify-content: center;
}
