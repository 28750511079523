.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
}
.embedContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 80vh;
  max-width: 1440px;
}

.embedContainer iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

@media (max-width: 990px) {
  .embedContainer {
    height: 50vh;
  }

  .embedContainer iframe {
    height: 50vh;
  }
}

@media (max-width: 600px) {
  .embedContainer {
    display: flex;
    justify-content: center;
    height: 30vh;
  }

  .embedContainer iframe {
    width: 100vw;
    height: 30vh;
  }
}
