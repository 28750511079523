.section {
  composes: section from './YoutubeSection.module.css';
  background-color: var(--background-color);
}

.title {
  composes: title from './YoutubeSection.module.css';
  color: #fff;
  font-size: 40px;
}
